import Grid from '@mui/material/Grid';
import useWindowSize from '@components/_hooks/useWindowSize'

const sx = {
    padding: {
        lg: 0,
        // xs: '0.1rem'
    },
}

const LayoutMain = (props) => {
    const {
        showSideNav,
        children
    } = props;

    const {isMobile} = useWindowSize();

    const lg = showSideNav ? 9 : 12;
    const xl = showSideNav ? 10 : 12;

    return (
        <Grid 
            item
            xs={12} lg={lg} xl={xl} 
            sx={{
                ...sx,
                paddingLeft: showSideNav && !isMobile ? '6px' : 0
            }}
        >
            {children} 
        </Grid>
    )
}

export default LayoutMain